<!-- eslint-disable prettier/prettier -->
<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/vouchers">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group label="Type">
                <b-form-input
                  :disabled="isDisabled == 1"
                  id="input-2"
                  v-model="type"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="'Voucher Amount ' + this.voucherAmountSign">
                <b-form-input
                  @keypress="onlyForCurrency"
                  id="input-2"
                  v-model="voucher_amount"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Expire duration *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  @keypress="isNumber"
                  v-model="expire_duration"
                  required
                  placeholder="Voucher Expire duration"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="isorderAmountFiledEnable"
                id="input-group-2"
                label="Minimum order amount €"
                label-for="input-2"
              >
                <b-form-input
                  @keypress="onlyForCurrency"
                  id="input-2"
                  v-model="minimum_order_amount"
                  required
                  placeholder="Minimum order amount"
                ></b-form-input>
              </b-form-group>
              <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              >
            </div>
          </div>
        </div>
        <br />

        <br />

        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteMeunItem="deleteMeunItem"
            @closeModal="closeModal"
          />
        </template>
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
export default {
  components: {
    VerficationModal,
  },
  data() {
    return {
      changeClasss: false,
      size: null,
      isDisabled: 0,
      isorderAmountFiledEnable: 0,
      id: "",
      voucherAmountSign: "€",
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      type: "",
      expire_duration: "",
      voucher_amount: "",
      minimum_order_amount: "",
      editvoucher: "",
      headerTitle: "",
      prevRoute: null,
      show: true,
      apiUrl: "/voucher/store",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    if (this.$router.history.current.path == "/edit-vouchers") {
      this.headerTitle = "Edit Voucher";
      document.title = "Edit Voucher";
      this.editDataApppend();
    }
  },
  methods: {
    editDataApppend() {
      this.isDisabled = 1;
      this.editvoucher = JSON.parse(localStorage.getItem("editvoucher"));
      this.type = this.editvoucher.type;
      if (this.type == "Order discount") {
        this.isorderAmountFiledEnable = 1;
        this.voucherAmountSign = "%";
      }
      this.minimum_order_amount = this.editvoucher.minimum_order_amount;
      this.expire_duration = this.editvoucher.expire_duration;
      this.voucher_amount = this.editvoucher.voucher_amount;
      this.id = this.editvoucher.id;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || $event.target.value.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        $event.target.value != null &&
        $event.target.value.indexOf(".") > -1 &&
        $event.target.value.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        const formData = new FormData();
        evt.preventDefault();
        if (this.alert) {
          evt.preventDefault();
          return false;
        }
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("type", this.type);
        formData.append("minimum_order_amount", this.minimum_order_amount);
        formData.append("voucher_amount", this.voucher_amount);
        formData.append("expire_duration", this.expire_duration);

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "vouchers" });
            } else {
              this.alert = true;
              this.apiResponceError = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
